import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RegistrationForm from '../components/forms/RegistrationForm'

const AboutPage = () => (
    <Layout>
      <SEO title="Location" />
      <RegistrationForm/>
    </Layout>
  )
  
  export default AboutPage
  