import { Formik } from 'formik'
import { Button, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import MyTextField from './MyTextField'
import MyRadio from './MyRadio'
import React, { useState } from 'react'
import axios from 'axios'
import * as yup from 'yup'
import { User } from '../../services/Api/User'



const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const postalnrRegExp = /^[0-9]{4}/
const validationSchema = yup.object({
    firstName: yup.string().required('Dette feltet er påkrevd.').max(50, 'Fornavn kan maksimalt være 50 tegn.'),
    lastName: yup.string().required('Dette feltet er påkrevd.').max(50, 'Etternavn kan maksimalt være 50 tegn.'),
    address: yup.string().required('Dette feltet er påkrevd.').max(50,'Adresse kan maksimalt være 50 tegn.'),
    postalNumber: yup.string().required('Dette feltet er påkrevd.').matches(postalnrRegExp,'Postnummeret er ugyldig').test('len', 'Postnummeret er ugyldig', val => val !== undefined && val.length === 4),
    postalLocation: yup.string().required('Dette feltet er påkrevd.').max(50,'Poststed kan maksimalt være 50 tegn'),
    eMail: yup.string().required('Dette feltet er påkrevd.').email('E-postadressen er ugyldig.'),
    phoneNumber: yup.string().required('Dette feltet er påkrevd.').matches(phoneRegExp,'Telefonnummeret er ugyldig'),
    password: yup.string().required('Dette feltet er påkrevd').min(8, 'Passordet må inneholde minst 8 tegn'),
    passwordCopy: yup.string().required('Dette feltet er påkrevd').oneOf([yup.ref('password'), null], 'Passordene er ikke like')
})


const RegistrationForm = () => {
    
    const b = 12

return(
<Formik initialValues={{ firstName: "", lastName:'' , address: '', postalNumber: '', postalLocation: '', eMail: '', phoneNumber:'', password:'', passwordCopy:''}}
                onSubmit={ (data, actions) => {
                actions.setSubmitting(true)
                User.new(data).then((res) =>{
                    if (res.data == 'E-postadressen er allerede i bruk.'){
                        actions.setFieldError('eMail','E-postadressen er allerede i bruk.')
                    }
                }).catch((err) => {
                    console.log(err)
                })
                actions.setSubmitting(false)
            }}
            validationSchema = {validationSchema}>
                {({values, errors, handleChange, handleBlur, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container justify="space-around" spacing={3}>
                        <Grid item xs={b} justify="space-around" container>
                        <MyTextField label="Fornavn" name="firstName" type="input" variant="filled" />
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                        <MyTextField label="Etternavn" name="lastName" type="input" variant="filled"/>
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>
                        <MyTextField label="Adresse" name="address" type="input" variant="filled"/>
                        </Grid>
                        
                        <Grid item xs={b} justify="space-around" container>
                        
                        <MyTextField label="Poststed" name="postalLocation" type="input" variant="filled"/>
                        </Grid>
                        <Grid item xs={b} justify="space-around" container>

                        <MyTextField label="Postnummer" name="postalNumber" type="input" variant="filled"/>
                        </Grid>
                        <Grid item xs={b}  justify="space-around" container>

                        <MyTextField label="E-post" name="eMail" type="input" variant="filled"/>
                        
                        </Grid>
                        <Grid item xs={b}  justify="space-around" container>

                        <MyTextField label="Telefon" name="phoneNumber" type="input" variant="filled"/>
                        </Grid>
                        <Grid item xs={b}  justify="space-around" container>

                        <MyTextField label="Passord" name="password" type="password" variant="filled"/>
                        </Grid>
                        <Grid item xs={b}  justify="space-around" container>

                        <MyTextField label="Gjenta Passord" name="passwordCopy" type="password" variant="filled"/>
                        </Grid>
                        <Grid item xs={b}  justify="space-around" container>                        
                        </Grid>
                        
                        <Grid item xs={b} justify="space-around" container>
                        <Button variant='contained' color='primary' type="Send">Registrer</Button>
                        </Grid>
                        <Grid item xs={b}  justify="space-around" container>
                        
                        </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>)
}

export default RegistrationForm